'use strict';

var Articles = require('../../articles/articles');
var articles = new Articles();

// Constructor
var Search = function () {
  this.name = 'search';

  window.page = 1;
  window.articleData = [];
  window.caseData = [];
  window.dataInitialised = false;
  window.shared_search_logic_array = articles.shared_search_logic_array;
  window.creditorportal_get_page_type_article = articles.creditorportal_get_page_type_article;

  // Set site mode.
  this.setSiteMode = function() {
    let type = window.creditorportal_get_page_type_article();
    if (type) {
        let site_mode_body_classes = Object.keys(window.shared_search_logic_array).map(function(key) {
          return window.shared_search_logic_array[key]['site_mode_body_class'];
      })
      jQuery('body').removeClass(site_mode_body_classes).addClass(window.shared_search_logic_array[type]['site_mode_body_class']);
    }
  }

  // Initial run.
  this.setSiteMode();

  /**
   * This function checks or unchecks the "All" checkboxes depending on which checkboxes are selected
   *
   * @param {String} category Can be "location" for the All Locations checkbox or "type" for the All Types of Appointment checkbox
   */
  window.checkUncheck = (category) => {
    let locationChks = document.querySelectorAll(".location-checkbox");
    let typeChks = document.querySelectorAll(".type-checkbox");

    // Changing state of CheckAll checkbox
    if (category === "location") {
      if (locationChks.length > 1) {
        if ($(".location-checkbox").length == $(".location-checkbox:checked").length) {
          $("#alllocations").prop("checked", true);
        } else {
          $("#alllocations").prop("checked", false);
        }
      }
      else {
        $("#alllocations").prop("checked", !$("#alllocations").prop("checked"));
      }
    }
    else if (category === "type") {
      if (typeChks.length > 1) {
        if ($(".type-checkbox").length == $(".type-checkbox:checked").length) {
          $("#alltypes").prop("checked", true);
        } else {
          $("#alltypes").prop("checked", false);
        }
      }
      else {
        $("#alltypes").prop("checked", !$("#alltypes").prop("checked"));
      }
    }
  }

  /**
   * This function gets the data stored in session storage and parses it as a JSON object
   *
   * @param {String} key Corresponds with the value (data) that is being retrieved
   */
  window.getSessionStorage = (key) => {
    return JSON.parse(sessionStorage.getItem(key));
  }

  /**
   * This function filters the cases based on the checkbox filters for location and appointment type. It is also used for pagination
   *
   * @param {Boolean} isPageReset true if the page number is being reset to 1, false if the page number is not being reset
   */
  window.filterCases = (isPageReset) => {
    let filters = [];
    $("input[type='checkbox']:checked").each(function () {
      filters.push($(this).val());
    });

    let data = getSessionStorage('data');
    caseData = [];

    for (let i = 0; i < data.length; i++) {
      if ((filters.includes("alltypes") || filters.includes(data[i].jobTypeText)) && (filters.includes("alllocations") || filters.includes(data[i].addressState))) {
        caseData.push(data[i]);
      }
    }
    if (isPageReset) {
      resetPageNumber();
    }
    repopulateCases();
    setPageButtonVisibility("cases");
  }

  /**
   * This function repopulates the cases on the page
   *
   */
  window.repopulateCases = () => {
    clearCases();

    let pageIndex = page - 1;
    let dataIndex = pageIndex * 10;

    let locationArray = document.getElementsByClassName("info1");
    let typeArray = document.getElementsByClassName("info2");

    if (caseData.length === 0) {
      $("#cases-list").append('<h4 class="no-results">No results found.</h4>')
    } else {
      for (let i = dataIndex; i < caseData.length && i < dataIndex + 10; i++) {
        $("#cases-list").append(
          `<li class="items all ${caseData[i].addressState}">
          <a class="link" onclick="disclaimer(${caseData[i].jobId})">
            <h4 class="title">${caseData[i].jobname}</h4>
            <div class="info">
              <span class="info1">${caseData[i].addressState}, ${caseData[i].addressCountry}</span>
              <span class="info2">${caseData[i].jobTypeText}</span>
            </div>
          </a>
        </li>`
        );
      }
      // prevent null values from being displayed
      for (let i = 0; i < locationArray.length; i++) {
        if (!caseData[i].addressState || !caseData[i].addressCountry) {
          locationArray[i].style.display = "none";
        }
        if (!caseData[i].jobTypeText) {
          typeArray[i].style.display = "none";
        }
      }
    }
  }

  /**
   * This function clears the cases in the search results table
   *
   */
  window.clearCases = () => {
    $("#cases-list").empty();
  }

  /**
   * This function calls the API to retrieve article search data
   *
   */
  window.initialiseData = function (type) {
    const queryString = window.location.search;
    $.ajax({
      url: `${window.insolvency_cms_root_api}${window.shared_search_logic_array[type]['url_path']}${queryString}`,
      type: "get",
      success: function (data) {
        articleData = data;
        repopulateArticles();
        setPageButtonVisibility("articles");
      }
    });
  }

  /**
   * This function handles keyword searches and updates the UI.
   *
   * @param {*} event Event passed from onkeyup
   * @param {String} type Type can be "articles" or "cases"
   */
  window.keywordSearch = function (event, type) {
    if (event.key == "Enter") {
      let keywordInputField = document.getElementById(`${type}-search`);
      let keywordText = keywordInputField.value;

      if (shared_search_logic_array[type]) {
        jQuery.ajax({
          url: `${window.insolvency_cms_root_api}${shared_search_logic_array[type]['url_path']}?keyword=${keywordText}`,
          type: "get",
          success: function (data) {
            articleData = data;
            dataInitialised = true;
            resetPageNumber(type);
            repopulateArticles();
          }
        });
      } else if (type === "cases") {
        let data = getSessionStorage('data');
        caseData = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].jobname.toLowerCase().includes(keywordText.toLowerCase()) ||
            data[i].jobTypeText.toLowerCase().includes(keywordText.toLowerCase())
            // || data[i].jobTypeText.toLowerCase().includes(keywordText.toLowerCase()) ||  *** Uncomment when production data is available
            // data[i].jobTypeText.toLowerCase().includes(keywordText.toLowerCase())
          ) {
            caseData.push(data[i]);
            $("input:checkbox").prop("checked", true);
          }
        }
        resetPageNumber();
        repopulateCases();
        setPageButtonVisibility("cases");
      }
    }
  }

  window.sitewideSearch = function (event) {
    if (event.key == "Enter") {
      let keywordInputField = document.getElementById('sitewide-search');
      let keywordText = keywordInputField.value;
      console.log(keywordText);
    }
  }

  /**
   * This function resets the page number back to 1.
   */
  window.resetPageNumber = function (type) {
    page = 1;
    $("#current-page-number").html(page);
    setPageButtonVisibility(type);
  }

  /**
   * This function handles pagination.
   *
   * @param {Integer} value
   * @param {string} type (articles page or cases page)
   */
  window.paginate = function (value, type) {
    page += value;
    $("#current-page-number").html(page);

    if (shared_search_logic_array[type]) {
      if (!dataInitialised) {
        initialiseData(type);
        dataInitialised = true;
      } else {
        repopulateArticles();
      }
    } else if (type === "cases") {
      filterCases(false);
    }
    setPageButtonVisibility(type);

    // scroll to the top of the list
    $([document.documentElement, document.body]).animate({
      scrollTop: $(".search-list").offset().top
    }, 1000);
  }

  /**
   * This function sets the visibility of the pagination buttons.
   */
  window.setPageButtonVisibility = function (type) {
    let data = [];

    if (shared_search_logic_array[type]) {
      data = articleData;
    } else if (type === "cases") {
      data = caseData;
    }

    // setting pagination button visibility
    if (page === 1) {
      $("#page-down").css("visibility", "hidden");
    } else {
      $("#page-down").css("visibility", "visible");
    }

    let maxPages = Math.ceil(data.length / 10);
    if (maxPages === 0 || page === maxPages) {
      $("#page-up").css("visibility", "hidden");
    } else {
      $("#page-up").css("visibility", "visible");
    }
  }

  /**
   * This function clears out the articles on the page.
   */
  window.clearArticles = function () {
    $("#article-list").empty();
  }

  /**
   * This function repopulates the articles on the page.
   *
   * @param {*} data
   */
  window.repopulateArticles = function () {
    let type = window.creditorportal_get_page_type_article();
    clearArticles();

    let pageIndex = page - 1;
    let dataIndex = pageIndex * 10;

    if (articleData.length === 0) {
      $("#article-list").append('<h4 class="no-results">No results found.</h4>')
    } else {
      for (let i = dataIndex; i < articleData.length && i < dataIndex + 10; i++) {
        let image_raw_array = articleData[i].image.split("\"");
        // Since 'lazy' in the dom, find url attr dynamically as find it and then
        // returning after it.
        let image_src = image_raw_array.find(function (val, i) {
          if ((typeof image_raw_array[i - 1] !== 'undefined')
            && (image_raw_array[i - 1].indexOf('src=') !== -1)) {
            return true;
          }
          return false;
        });
        let image_attr = "";
        for (let j = 2; j < image_raw_array.length; j++) {
          if (j === 7) {
            image_attr = image_attr.concat(`"${image_raw_array[j]}"`);
          } else {
            image_attr = image_attr.concat(image_raw_array[j]);
          }
        }

        // get tags
        let tagsArray = articleData[i].tags.split(", ");
        let tags = "";
        for (let j = 0; j < tagsArray.length; j++) {
          let tag_data = tagsArray[j].split(">");
          let tag_href = tag_data[0].split("/");
          let tagId = tag_href[tag_href.length - 1].split("\"")[0];
          let tag = tag_data[1].split("<")[0];
          tags += `<a href="/${window.shared_search_logic_array[type]['url_listing_page']}.html?tags=${tagId}" class="tag">${tag}</a>`;
        }

        let bodyTitle = articleData[i].body_title.replace(/<\/?[^>]+(>|$)/g, "");

        $("#article-list").append(
          `<li class="items">
              <div class="img-container">
                <a class="image-link" href="/${window.shared_search_logic_array[type]['url_page_details']}.html?id=${articleData[i].id}">
                  <img src="${window.insolvency_cms_root_url_no_slash}${image_src}" class="image" ${image_attr}
                </a>
              </div>
              <a href="/${window.shared_search_logic_array[type]['url_page_details']}.html?id=${articleData[i].id}" class="link">
                  <h4 class="title">${articleData[i].title}</h4>
              </a>
              <div class="description">${bodyTitle}</div>

              <div class="info">
                  <span class="info1">${articleData[i].published_date}</span>
                  <span class="info2">${articleData[i].author_name}</span>
              </div>
              <div class="tags">
                  ${tags}
              </div>
          </li>`
        );
      }
    }
  }

  /**
   * This function closes the filters dropdown box
   */
  window.hideFilterBox = function () {
    $('.overlay-active').removeClass('overlay-active');
  }

}

module.exports = Search;
