// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';
window.charts_collection = {}; // Contains all the the chart objects

window.insolvency_cms_root_url = `${process.env.ROOT_URL}`;
window.insolvency_cms_root_url_no_slash = `${process.env.ROOT_URL_NO_SLASH}`;
window.insolvency_cms_root_api = `${process.env.ROOT_API}`;

// var $ = require('jquery');
// including module from the dashboard portal
// TODO: Move these common modules outside the project in
// a common folder
var DropDownBox = require('../_modules/common/drop-down-box/drop-down-box');
var ContentOverlay = require('../_modules/common/content-overlay/content-overlay');

//--

var Search = require('../_modules/common/search/search');
var Redirect = require('../_modules/common/redirect/redirect');
var HomepageBanner = require('../_modules/homepage/homepage-banner/homepage-banner');
var HowCanWeHelp = require('../_modules/homepage/how-can-we-help/how-can-we-help');
var FormalInsolvency = require('../_modules/homepage/formal-insolvency/formal-insolvency');
var OurLatestThinking = require('../_modules/homepage/our-latest-thinking/our-latest-thinking');
var TheTeam = require('../_modules/homepage/the-team/the-team');
var Login = require('../_modules/common/login/login');
var CaseDetails = require('../_modules/case.details/case.details');
var FooterBottom = require('../_modules/common/footer/footer-bottom/footer-bottom');
var FooterTop = require('../_modules/common/footer/footer-top/footer-top');
var Articles = require('../_modules/articles/articles');
var ArticleDetails = require('../_modules/article.details/article.details');
var CampaignDetails = require('../_modules/campaign.details/campaign.details');
var CurrentCases = require('../_modules/current.cases/current.cases');
var PeopleDetails = require('../_modules/people.details/people.details');
var ConnectWithUs = require('../_modules/connect.with.us/connect.with.us');
var LegalAndPrivacy = require('../_modules/legal/legal-and-privacy');
var Loader = require('../_modules/common/loader/loader');
var SiteMap = require('../_modules/site.map/site.map');
var FixLinks = require('../_modules/common/fix-links/fix-links');
var Error = require('../_modules/error/error');

$(function () {
    // Add to Ajax complete.
    new FixLinks();

    // Search - Define global variables.
    new Search();

    // Modules shared across all pages
    new Redirect(); // Load the public website
    new Login();
    new DropDownBox();
    new ContentOverlay();
    new FooterTop();
    new FooterBottom();
    new Loader();

    if ($('.homepage').length > 0) {
        new HomepageBanner();
        new HowCanWeHelp();
        new FormalInsolvency();
        new OurLatestThinking();
        new TheTeam();
    } else if ($('.article-details, .aged-care-case-study-details').length > 0) {
        new OurLatestThinking();
        let type = $('.article-details').length ? 'articles' : 'aged-care-case-studies';
        new ArticleDetails(type);
    } else if ($('#articles-page').length > 0) {
        new Articles('articles');
    } else if ($('#aged-care-case-studies-page').length > 0) {
        new Articles('aged-care-case-studies');
    } else if ($('.connect').length > 0) {
        new ConnectWithUs();
    } else if ($('.current-cases').length > 0) {
        new CurrentCases();
    } else if ($('.case-details').length > 0) {
        new CaseDetails();
    } else if ($('.layout-container.campaign-details').length > 0) {
        new CampaignDetails();
    } else if ($('.legal-and-privacy').length > 0) {
        new LegalAndPrivacy();
    } else if ($('.people-details').length > 0) {
        new PeopleDetails();
        new TheTeam();
    } else if ($('.site-map').length > 0) {
        new SiteMap();
    } else if ($('.layout-container.error-page').length > 0) {
        new Error();
    }
    // Run again to catch any links missed.
    new FixLinks();
});
