'use strict';

var Articles = require('../../articles/articles');
var articles = new Articles();

// Constructor
var OurLatestThinking = function () {
    $('.our-latest-thinking li').matchHeight();

    let type = window.creditorportal_get_page_type_article();
    if (!type) {
        type = 'articles';
    }

    jQuery.ajax({
        url: `${process.env.ROOT_API}${articles.shared_search_logic_array[type]['url_related_list']}`,
        type: "get",
        success: function(data){
            populateArticles(data);
            showData();
            setTileHeights();
        },
        error: function() {
            $("#list").append("<br><p style='text-align:center; font-size: 1.25em'>Unable to load article content. Please try again.</p>");
            showData();
        }
    });
};

/**
 * This function renders articles on to the page.
 *
 * @param {Array} data The array of articles objects to be rendered.
 */
var populateArticles = (data) => {
    let type = window.creditorportal_get_page_type_article();
    if (!type) {
        type = 'articles';
    }

    let isMediumScreen = window.matchMedia("only screen and (min-width: 1025px) and (max-width: 1404px)").matches;

    let max_articles = 4;
    if (isMediumScreen) {
        max_articles = 3;
    }

    let i = 0;

    // limit number of articles shown to 8 (4 on mobile)
    while (i < data.length && i < max_articles) {
        // extract image src
        let image_raw_array = data[i].image.split("\"");
        // Since 'lazy' in the dom, find url attr dynamically as find it and then
        // returning after it.
        let image_src = image_raw_array.find(function (val, i) {
            if ((typeof image_raw_array[i - 1] !== 'undefined')
                && (image_raw_array[i - 1].indexOf('src=') !== -1)) {
                return true;
            }
            return false;
        });
        let image_attr = "";
        for (let j = 2; j < image_raw_array.length; j++) {
            if (j === 7) {
                image_attr = image_attr.concat(`"${image_raw_array[j]}"`);
            } else {
                image_attr = image_attr.concat(image_raw_array[j]);
            }
        }

        // remove HTML tags from article description
        let article_desc = data[i].body_title.replace(/<\/?[^>]+(>|$)/g, "");

        // only display the first author
        let author_array = data[i].author_name.split(",");
        let first_author = author_array[0];
        let read_time = data[i].read_time;

        $("#list").append(
            `<li>
                <a href="/${process.env.ROOT_API}${articles.shared_search_logic_array[type]['url_page_details']}.html?id=${data[i].id}" class="inner">
                    <div class="img-container">
                        <img src="${process.env.ROOT_URL}${image_src}" class="image" ${image_attr}
                    </div>
                    <h5 class="title">${data[i].title}</h5>
                    <div class="info">
                        <div class="description">${article_desc}</div>
                        <div class="date">${data[i].published_date}</div>
                        <div class="author">${first_author}</div>
                        <div class="read-time">${read_time}</div>
                    </div>
                </a>
            </li>`
        );
        i++;
    }
}

/**
 * Once the data has been rendered, this function removes the loading icon and shows the content on the page.
 */
var showData = () => {
    $('.our-latest-thinking').show();
}

/**
 * This function gets the maximum height out of the tiles onscreen and sets all tiles to this height.
 */
var setTileHeights = () => {
    let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

    // only resize height of tiles if not on mobile
    if (!isMobile) {
        let max = -1;
        // find max tile height
        $(".our-latest-thinking .tiles li").each(function() {
            let height = $(this).height();
            max = height > max ? height : max;
        });
        // set height of all tiles to match
        $(".our-latest-thinking .tiles li").each(function() {
            $(this).height(max);
        });
    }
}

module.exports = OurLatestThinking;
